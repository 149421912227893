import { useStaticQuery, graphql } from "gatsby";

export const getProfilePic = (): any => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      pic: file(relativePath: { eq: "michaels_face.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 90
            height: 200
            width: 200
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return data.pic.childImageSharp.gatsbyImageData;
};
