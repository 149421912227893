import React from "react";
import classNames from "classnames";
import { ThemeOption } from "types";
import { GLOBAL_THEME_CONFIG } from "theme/config";

type ExternalLinkProps = {
  href: string;
  title: string;
  theme: ThemeOption;
};
export const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  title,
  children,
  theme,
}) => {
  return (
    <a
      href={href}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames("font-bold", {
        [GLOBAL_THEME_CONFIG.dark.link]: theme === "dark",
        [GLOBAL_THEME_CONFIG.dark.linkHover]: theme === "dark",
        [GLOBAL_THEME_CONFIG.light.link]: theme === "light",
        [GLOBAL_THEME_CONFIG.light.linkHover]: theme === "light",
      })}
    >
      {children}
    </a>
  );
};
