import React, { useContext } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { PageProps, PageThemeConfig } from "types";
import { Nav } from "components/nav";
import { getProfilePic } from "components/profile_pic";
import classNames from "classnames";
import { ThemeContext } from "context/theme";
import { ExternalLink } from "components/external_link";
import { Page } from "components/page";
import { Footer } from "components/footer";
import { IoMdMusicalNote } from "react-icons/io";
import { FaLinkedin, FaGithub, FaCode, FaEnvelope } from "react-icons/fa";
import { DeepRequired } from "ts-essentials";
import { Main } from "components/main";

const themeConfig: DeepRequired<PageThemeConfig> = {
  dark: {
    background: "bg-gray-800",
    textOnBackground: "text-white",
  },
  light: {
    textOnBackground: "text-gray-900",
    background: "bg-white",
  },
};

export const Emoji: React.FC<{ src: string }> = ({ src }) => {
  return <span className="pl-1">{src}</span>;
};

const MusicalLi: React.FC = ({ children }) => {
  return (
    <li className="px-2 pb-1 text-base relative">
      <IoMdMusicalNote className="absolute" style={{ left: -18, top: 3 }} />
      <span>{children}</span>
    </li>
  );
};

const CodeLi: React.FC = ({ children }) => {
  return (
    <li className="px-2 pb-1 text-base relative">
      {" "}
      <FaCode className="absolute" style={{ left: -20, top: 3 }} />
      <span>{children}</span>
    </li>
  );
};

const AboutPage: React.FC<PageProps> = () => {
  const pic = getProfilePic();
  const { theme, turnDarkModeOn, turnLightModeOn } = useContext(ThemeContext);

  return (
    <Page
      title="About"
      description="About Michael Hueter"
      themeConfig={themeConfig}
    >
      <Nav />
      <Main theme={theme}>
        <div className="flex flex-wrap">
          <div className="w-full md:w-3/4 lg:pr-12 flex flex-col justify-center">
            <div className="flex my-8">
              <h2
                className={classNames("text-2xl", {
                  [themeConfig.dark.textOnBackground]: theme === "dark",
                  [themeConfig.light.textOnBackground]: theme === "light",
                })}
              >
                Michael Hueter
              </h2>
            </div>
            <p className="text-lg md:pr-1">
              Welcome to my site! <Emoji src="👋" /> I&apos;m a software
              engineer by
              <span
                className={classNames(
                  {
                    "text-orange-300": theme === "dark",
                    "text-orange-500": theme === "light",
                  },
                  "cursor-pointer",
                  "font-bold"
                )}
                onClick={turnLightModeOn}
              >
                {" "}
                day
              </span>
              , musician by{" "}
              <span
                className={classNames(
                  {
                    "text-blue-400": theme === "dark",
                    "text-blue-800": theme === "light",
                  },
                  "cursor-pointer",
                  "font-bold"
                )}
                onClick={turnDarkModeOn}
              >
                night.
              </span>{" "}
              I also teach &mdash; both music and coding.
            </p>
            <div className="flex items-center mt-5">
              <hr
                className={classNames("border-1 flex-grow", {
                  "border-white": theme === "dark",
                  "border-gray-600": theme === "light",
                })}
              />
              <a
                className={classNames("text-4xl hover:text-indigo-500 ml-10", {
                  "hover:text-indigo-600": theme === "light",
                  "hover:text-pink-500": theme === "dark",
                })}
                href="https://github.com/hueter"
                title="Github"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub />
              </a>
              <a
                className={classNames("text-4xl ml-10", {
                  "text-blue-700": theme === "light",
                  "hover:text-indigo-600": theme === "light",
                  "hover:text-pink-500": theme === "dark",
                })}
                href="https://linkedin.com/in/michael-hueter"
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
              <a
                className={classNames("text-4xl ml-10", {
                  "text-indigo-800": theme === "light",
                  "hover:text-indigo-600": theme === "light",
                  "hover:text-pink-500": theme === "dark",
                })}
                href="mailto:michael@michaelhueter.com"
                title="Email"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaEnvelope />
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/4 mt-6 lg:mt-0 flex justify-center">
            <GatsbyImage
              image={pic}
              alt="Michael's face"
              className="rounded-full shadow-inner"
            />
          </div>
        </div>

        <h2 className="text-lg mt-6 mb-4">As a developer I...</h2>
        <ul className="pl-6">
          <CodeLi>
            have been coding full-time since 2015 as a full-stack web developer
          </CodeLi>
          <CodeLi>
            love working at start-ups! <Emoji src="🚀" /> I&apos;m currently a
            staff engineer at a
            <ExternalLink href="https://numero.ai" title="Numero" theme={theme}>
              {" "}
              YC startup tackling campaign finance
            </ExternalLink>
          </CodeLi>
          <CodeLi>
            taught web development as a lead instructor at an{" "}
            <ExternalLink
              href="https://rithmschool.com"
              theme={theme}
              title="Rithm School"
            >
              awesome coding bootcamp
            </ExternalLink>{" "}
          </CodeLi>
          <CodeLi>
            code in <del>JavaScript</del> TypeScript, Python, and Ruby. Also,
            GraphQL <Emoji src="🎉" />
          </CodeLi>
        </ul>
        <h2 className="text-lg mt-6 mb-4">As a musician I...</h2>
        <ul className="pl-6">
          <MusicalLi>
            play bass guitar as my main instrument since 2005, with some guitar
            and piano on the side
          </MusicalLi>
          <MusicalLi>
            studied composition and worked with a composer in college{" "}
            <Emoji src="🎹" />
          </MusicalLi>
          <MusicalLi>
            have taught private music lessons and tutored in music theory /
            composition since 2013
          </MusicalLi>
        </ul>
      </Main>
      <Footer theme={theme} />
    </Page>
  );
};

export default AboutPage;
